<template>
  <div class="layout-default">
    <BlockHeader :links="headerLinks" />
    <slot />

    <BlockServiceMenu
      v-if="serviceMenu?.show_service_navigation"
      :data="serviceMenu"
      :form-data="newsletterForm"
    />

    <BlockFooter v-bind="footer" />
  </div>
</template>

<script setup>
defineProps({
    headerLinks: {
        type: Array,
        default: () => [],
    },

    footer: {
        type: Object,
        default: () => {},
    },

    serviceMenu: {
        type: Object,
        default: () => {},
    },

    newsletterForm: {
        type: Object,
        default: () => {},
    },
});

/*
    Set the language of the page
*/
const { locale } = useI18n();
useHead({
    htmlAttrs: {
        lang: locale.value,
    },
});

/*
    Get ip address and check if it is the same as the admin ip
*/
const authStore = useAuthStore();
const settingsStore = useSettingsStore();
const loginData = computed(() => settingsStore.getSetting(
    'login',
));

const getLocalIpAdress = async () => {
    const { ip } = await $fetch('https://api.ipify.org?format=json');

    if (ip === loginData.value.adminIp) {
        authStore.adminLogin();
    }
};

onMounted(() => {
    getLocalIpAdress();
});

/*
    Update the legaly ok snippet
*/
const route = useRoute();
const apiLegallyOk = {
    impressum: 'https://legally-snippet.legal-cdn.com/v1/public/websites/5s4N1Sqrar8M6CFk5K7mAN4wBMTv8E/imprints/latest/snippet.html',
    datenschutz: 'https://legally-snippet.legal-cdn.com/v1/public/websites/j0IQvwtpU3NWVnoj4mNHvY5ahELowf/policies/latest/snippet.html',
};

const {
    data: legallyOkContent, execute,
} = useLazyAsyncData(
    'legally-ok-content',
    () => $fetch(apiLegallyOk[route.path.split('/')[1]]),
    {
        immediate: false,
    },
);

execute();

watch(() => route.path, (newValue) => {
    // Prepare path and add type checks
    if (!newValue) return;

    const splittedPath = newValue.split('/');

    const rootPath = Array.isArray(splittedPath) && splittedPath.length > 0
        ? splittedPath[1] : null;

    if (!rootPath) return;

    // Only execute when it needs to
    if (Object.keys(apiLegallyOk).includes(rootPath)) {
        execute();
    }
}, { immediate: true });

// provide the content to the component
provide('legallyOkContent', legallyOkContent);
</script>

<style lang="scss">
.layout-default {
    position: relative;
}
</style>
